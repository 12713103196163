<template>
  <div class="connect">
    <template v-if="linked">
      <img src="../../assets/images/success.svg" />
      <h1>
        You've successfully setup {{ accounts }} bank
        {{ accounts != 1 ? "accounts" : "account" }} for auto-debiting.
      </h1>
      <p>You can close this window now.</p>
    </template>
    <template v-if="canceled">
      <img src="../../assets/images/error.svg" />
      <h1>You failed to set up auto-debit.</h1>
      <p>Do you want to retry?</p>
      <button class="button" @click="showTransfersModal">Retry</button>
    </template>
  </div>
</template>

<script>
import { refreshTransfersConnections } from "../../utilities/api";

export default {
  states: ["linking", "linked", "canceled"],
  data() {
    return {
      accounts: 0,
    };
  },
  methods: {
    async showTransfersModal() {
      this.currentState = "linking";

      const userId = this.$route.query.u;
      const result = await Transfers.connect({ userId });
      if (result) {
        this.accounts = result.length;
        this.currentState = "linked";
        refreshTransfersConnections({ userId });
      } else this.currentState = "canceled";
    },
  },
  mounted() {
    this.showTransfersModal();
  },
};
</script>

<style lang="scss" scoped>
.connect {
  padding: 2.4rem;

  img {
    height: 3.6rem;
    margin-bottom: 2.4rem;
  }

  h1 {
    margin-bottom: 0;

    + p {
      display: block;
      margin-bottom: 2.4rem;
    }
  }
}
</style>
